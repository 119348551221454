<template>
  <div>
    <img alt="This is me!" class="mx-auto rounded-full shadow-lg border-white border-4 mt-24"
         src="@/assets/img/portrait.jpg"
         width="150px">
    <div class="text-center mt-6">
      <h1 class="text-2xl" style="font-family: 'Caveat', cursive;">Benjamin Nater</h1>
      <h2>Golang developer. FOSS enthusiast.</h2>
    </div>
    <div class="flex flex-wrap items-center justify-center max-w-xs mx-auto my-5" title="Github">
      <a class="rounded-full p-2 hover:bg-gray-300 " href="https://github.com/bn4t" target="_blank">
        <svg class="feather feather-github" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
        </svg>
      </a>
      <a class="rounded-full p-2 hover:bg-gray-300 " href="https://twitter.com/benjamin_nater" target="_blank" title="Twitter">
        <svg class="feather feather-twitter" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
        </svg>
      </a>
      <a class="rounded-full p-2 hover:bg-gray-300 " href="https://www.linkedin.com/in/benjamin-nater/" target="_blank" title="LinkedIn">
        <svg class="feather feather-linkedin" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
          <rect height="12" width="4" x="2" y="9"></rect>
          <circle cx="4" cy="4" r="2"></circle>
        </svg>
      </a>
      <a class="rounded-full p-2 hover:bg-gray-300 " href="mailto:benjamin@nater.net" target="_blank" title="Email">
        <svg class="feather feather-mail" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
          <polyline points="22,6 12,13 2,6"></polyline>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}

</script>
